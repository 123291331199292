<template>
  <div class="component-publishtestdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Pubblica la configurazione del Test</v-card-title>

        <v-card-text>
          <p>
            <strong>Vuoi pubblicare la configurazione del Test?</strong>
            Continuando tutte le eventuali modifiche apportate verranno rese
            pubbliche.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="grey" text
            :disabled="loading"
            @click="cancel">
            Annulla
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="success" text
            :loading="loading"
            @click="publish()">
            Pubblica
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'PublishTestDialog',

  props: ['value', 'config'],

  data: () => ({
    dialog: false,
    loading: false,
  }),

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async publish() {
      try {
        this.loading = true;
        const db = firebase.firestore();
        await db.doc('/helpers/wizard').set({
          stages: this.config,
        }, { merge: true });
        this.$emit('published');
      } catch (error) {
        console.error('Errore durante la pubblicazione:', error);
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>
