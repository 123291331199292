/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import Vue from 'vue';
import VueRouter, { NavigationDuplicated } from 'vue-router';
import store from '@/store';
import Login from '@/views/Login';
import ResetAsk from '@/views/ResetAsk';
import Listini from '@/views/Listini';
import CartelleColori from '@/views/CartelleColori';
import CartellaColori from '@/views/CartellaColori';
import Preventivi from '@/views/Preventivi';
import Preventivo from '@/views/Preventivo';
import PreventivoPDF from '@/views/PreventivoPDF';
import NuovoPreventivo from '@/views/NuovoPreventivo';
import ModificaPreventivo from '@/views/ModificaPreventivo';
import Prodotti from '@/views/Prodotti';
import Prodotto from '@/views/Prodotto';
import NuovoProdotto from '@/views/NuovoProdotto';
import Fasi from '@/views/Fasi';
import Fase from '@/views/Fase';
import Utenti from '@/views/Utenti';
import Profilo from '@/views/Profilo';
import ElencoTag from '@/views/ElencoTag';
import Ripara from '@/views/Ripara';
import Notifiche from '@/views/Notifiche';
import Test from '@/views/Test';
import AggiornaListini from '@/views/AggiornaListini';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login',
  },
  // Redirect di default verso l'elenco dei preventivi
  // (verrà sostituito con la prima view a cui l'utente ha accesso)
  {
    path: '/',
    redirect: '/preventivi',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // Registrazione nuovi utenti
  {
    path: '/registrati',
    name: 'Registrati',
    component: () => import('@/views/Registrati.vue'),
  },
  {
    path: '/reset/ask',
    name: 'ResetAsk',
    component: ResetAsk,
  },
  {
    path: '/ripara',
    name: 'Ripara',
    component: Ripara,
  },
  {
    path: '/listini',
    name: 'Listini',
    component: Listini,
  },
  {
    path: '/cartelle',
    name: 'CartelleColori',
    component: CartelleColori,
  },
  {
    path: '/cartelle/:id',
    name: 'CartellaColori',
    component: CartellaColori,
  },
  {
    path: '/preventivi',
    name: 'Preventivi',
    component: Preventivi,
  },
  {
    path: '/preventivi/nuovo/:type',
    name: 'NuovoPreventivo',
    component: NuovoPreventivo,
  },
  {
    path: '/preventivi/:id/modifica',
    name: 'ModificaPreventivo',
    component: ModificaPreventivo,
  },
  {
    path: '/preventivi/:id/pdf',
    name: 'PreventivoPDF',
    component: PreventivoPDF,
  },
  {
    path: '/preventivi/:id',
    name: 'Preventivo',
    component: Preventivo,
  },
  {
    path: '/fasi',
    name: 'Fasi',
    component: Fasi,
  },
  {
    path: '/fasi/:id',
    name: 'Fase',
    component: Fase,
  },
  {
    path: '/prodotti',
    name: 'Prodotti',
    component: Prodotti,
  },
  {
    path: '/prodotti/nuovo',
    name: 'NuovoProdotto',
    component: NuovoProdotto,
  },
  {
    path: '/prodotti/:id',
    name: 'Prodotto',
    component: Prodotto,
  },
  {
    path: '/tag',
    name: 'ElencoTag',
    component: ElencoTag,
  },
  {
    path: '/utenti',
    name: 'Utenti',
    component: Utenti,
  },
  {
    path: '/notifiche',
    name: 'Notifiche',
    component: Notifiche,
  },
  {
    path: '/profilo',
    name: 'Profilo',
    component: Profilo,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
  },
  // Redirect verso le app
  {
    path: '/app',
    name: 'AppDownload',
    component: () => import('@/views/AppDownload.vue'),
  },
  {
    path: '/ios',
    beforeEnter() {
      window.location.href = 'https://apps.apple.com/it/app/keim-easy/id6457364728';
    },
  },
  {
    path: '/android',
    beforeEnter() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.keimeasy.app';
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/aggiorna-listini',
    name: 'AggiornaListini',
    component: AggiornaListini,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Verifica se l'utente ha accesso alla route selezionata
function hasAccessTo(route) {
  console.log('beforeEach() hasAccessTo() route.path =', route.path);

  // Il superUser ha accesso a tutte le route
  if (store.getters.currentUser && store.getters.currentUser.superUser) {
    console.log('beforeEach() hasAccessTo() -> store.getters.currentUser.superUser =', !!store.getters.currentUser.superUser);
    return true;
  }

  // Per le route /preventivi e /prodotti l'utente deve aver verificato l'email
  if (route.path === '/preventivi' || route.path.startsWith('/preventivi/')
    || route.path === '/prodotti' || route.path.startsWith('/prodotti/')) {
    // currentUser deve essere definito
    if (!store.getters.currentUser) {
      console.log('beforeEach() hasAccessTo() -> store.getters.currentUser =', !!store.getters.currentUser);
      return false;
    }
    if (!store.getters.currentUser.loggedIn) {
      console.log('beforeEach() hasAccessTo() -> store.getters.currentUser.loggedIn =', !!store.getters.currentUser.loggedIn);
      return false;
    }
    if (!store.getters.isEmailVerified) {
      console.log('beforeEach() hasAccessTo() -> store.getters.isEmailVerified =', !!store.getters.isEmailVerified);
      return false;
    }
    console.log('beforeEach() hasAccessTo() -> true');
    return true;
  }

  // Per le route /profilo l'utente deve essere autenticato
  if (route.path === '/profilo') {
    if (!store.getters.currentUser) {
      console.log('beforeEach() hasAccessTo() -> store.getters.currentUser =', !!store.getters.currentUser);
      return false;
    }
    if (!store.getters.currentUser.loggedIn) {
      console.log('beforeEach() hasAccessTo() -> store.getters.currentUser.loggedIn =', !!store.getters.currentUser.loggedIn);
      return false;
    }

    console.log('beforeEach() hasAccessTo() -> true');
    return true;
  }

  // In tutti gli altri casi l'utente non ha accesso
  console.log('beforeEach() hasAccessTo() -> false');
  return false;
}

function pushRouteTo(route) {
  console.log('beforeEach() pushRouteTo() route.path =', route.path);
  try {
    router.push(route);
  } catch (error) {
    console.error('beforeEach() pushRouteTo() error =', error); // eslint-disable-line no-console
    if (!(error instanceof NavigationDuplicated)) {
      // throw error;
    }
  }
}

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  console.log('beforeEach() to.path =', to.path);

  // Se non è richiesta l'autenticazione...
  const publicPages = ['/login', '/registrati', '/reset/ask', '/reset/set', '/privacy', '/app', '/ios', '/android'];
  const authRequired = !publicPages.includes(to.path)
    // HACK: Permette l'accesso ai preventivi richiesti da Firebase Functions per le esportazioni
    // FIXME: Sostituire con un controllo più accurato
    && !to.path.startsWith('/preventivi/');
  console.log('beforeEach() authRequired =', !!authRequired);
  // ... passa direttamente alla pagina pubblica.
  if (!authRequired) return next();

  // Se l'utente è già autenticato...
  if (store.getters.currentUser && store.getters.currentUser.loggedIn) {
    console.log('beforeEach() store.getters.currentUser.loggedIn =', !!store.getters.currentUser.loggedIn);

    // Se il path richiesto è `/preventivi`...
    if (to.path === '/preventivi') {
      console.log(`beforeEach() from.path = ${from.path} and to.path = ${to.path}`); // eslint-disable-line no-console

      // Se l'utente ha accesso a `/preventivi`...
      if (hasAccessTo(to)) {
        console.log(`beforeEach() hasAccessTo({ path: ${to.path} }) === true`); // eslint-disable-line no-console
        // ... effettua il redirect verso la route selezionata
        // return router.push({ path: to.path }).catch(() => {});
        // return pushRouteTo({ path: to.path });
        return next();
      }

      // ... effettua il redirect verso la prima view a cui l'utente ha accesso
      for (const path of ['/preventivi', '/prodotti', '/profilo']) {
        console.log('beforeEach() path =', path); // eslint-disable-line no-console

        if (hasAccessTo({ path })) {
          console.log(`beforeEach() hasAccessTo({ path: ${path} }) === true`); // eslint-disable-line no-console
          // return router.push({ path }).catch(() => {});
          return pushRouteTo({ path });
        }
      }
    }

    // Altreimenti, verifica se l'utente ha accesso alla route selezionata
    return hasAccessTo(to)
      ? next()
      // ... altrimenti, passa alla pagina di login.
      : next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
  }

  console.log('beforeEach() store.getters.currentUser.loggedIn =', !!store.getters.currentUser.loggedIn);

  // L'utente non è autenticato, quindi passa alla pagina di login.
  return next({
    path: '/login',
    query: {
      redirect: to.fullPath,
    },
  });
});

export default router;
