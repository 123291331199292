<template>
  <v-app :class="{ print: print }">
    <v-navigation-drawer app temporary
      v-if="isLoggedIn && !print"
      v-model="drawer">
      <v-list nav dense>
        <v-list-item v-if="isLoggedIn"
          :disabled="!isEmailVerified"
          :to="{ name: 'Preventivi' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-file-document-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Preventivi</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isLoggedIn"
          :disabled="!isEmailVerified"
          :to="{ name: 'Prodotti' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-format-list-text</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Prodotti</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isSuperUser"
          :to="{ name: 'Fasi' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-progress-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Fasi di lavorazione</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isSuperUser"
          :to="{ name: 'Listini' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-notebook</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Listini</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isSuperUser"
          :to="{ name: 'CartelleColori' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-palette</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cartelle colori</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isSuperUser"
          :to="{ name: 'ElencoTag' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-tag-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tag</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isSuperUser"
          :to="{ name: 'Utenti' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Utenti</v-list-item-title>
        </v-list-item>

        <!-- Gestione delle notifiche -->
        <v-list-item v-if="isSuperUser"
          :to="{ name: 'Notifiche' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Notifiche</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item v-if="isSuperUser"
          :to="{ name: 'Test' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Configurazione Test</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isSuperUser"
          :to="{ name: 'AggiornaListini' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-currency-eur</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Aggiorna Listini</v-list-item-title>
        </v-list-item> -->

        <v-divider></v-divider>

        <v-list-item :to="{ name: 'Profilo' }" @click="drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Profilo</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Esci</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark
      extension-height="20" absolute
      v-if="!print">
      <template v-slot:extension>
        <router-link :to="{ name: 'Preventivi' }" class="keim-header-logo-container">
          <img src="@/assets/logo-app_bar-v3.png" class="keim-header-logo" />
        </router-link>
      </template>

      <v-app-bar-nav-icon
        v-if="isLoggedIn"
        @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <!-- v-toolbar-title class="display-1 text-uppercase">
        <span class="font-weight-medium">KEIM</span>
        <span class="font-weight-light">Easy</span>
      </v-toolbar-title -->

      <v-spacer></v-spacer>

      <v-toolbar-items v-if="isLoggedIn" class="d-none d-md-flex">
        <v-btn depressed color="primary"
          :to="{ name: 'Profilo' }">
          <v-icon left>mdi-account</v-icon>
          <span v-text="currentUser.email"></span>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container class="main-container"
        :class="{
          'fill-height': ['Login', 'Registrati', 'ResetAsk', 'ResetSet'].includes($router.currentRoute.name),
        }" fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer padless v-if="!print">
      <v-col class="text-center caption" cols="12">
        KEIM Easy è un software creato da OWL CAVE LABS di Giacomo Antolini &copy; {{ new Date().getFullYear() }}<br>
        per <strong>KEIMFARBEN COLORI MINERALI S.R.L.</strong>
        - P.IVA: 00706290210
        - <router-link :to="{ name: 'Privacy' }">Informativa sul trattamento dei dati personali</router-link><br>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { LOGOUT } from '@/store/actions/auth';

export default {
  name: 'App',

  data: () => ({
    drawer: false,
  }),

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },

    isLoggedIn() {
      return this.currentUser.loggedIn;
    },

    isEmailVerified() {
      return this.$store.getters.isEmailVerified;
    },

    isSuperUser() {
      return this.currentUser.superUser;
    },

    print() {
      return Boolean(this.$router.currentRoute.name === 'PreventivoPDF');
    },
  },

  methods: {
    logout() {
      this.$store.dispatch(LOGOUT)
        .then(() => {
          console.log('LOGOUT success'); // eslint-disable-line no-console

          this.$router.replace({ name: 'Login' }, () => {});
        });
    },
  },
};
</script>

<style lang="scss">
@page {
  size: A4 portrait;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  -webkit-print-color-adjust: exact;
}

.keim-header {
  -webkit-print-color-adjust: exact;
  margin-top: -12px;
  margin-bottom: 3rem;
  height: 100px;
  padding-top: 30px;

  @media (min-width: 960px) {
    // height: 100px;
  }
}

.keim-header-logo-container {
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.keim-header-logo {
  width: 120px;
  // padding-top: 10px;
  // margin-left: auto;
  // margin-right: auto;

  @media (min-width: 960px) {
    width: 150px;
    // padding-top: 10px;
  }
}

.container.main-container {
  padding-top: 40px;

  @media (min-width: 960px) {
    padding-top: 20px;
  }
}
</style>
