<template>
  <div class="view-aggiorna-listini">
    <v-alert
      v-if="!isSuperUser"
      type="warning"
      text
    >
      Non hai i permessi necessari per accedere a questa pagina.
    </v-alert>

    <template v-else>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8">
          <v-card>
            <v-card-title>
              Aggiornamento Listini
            </v-card-title>

            <v-card-text>
              <v-alert
                type="info"
                text
              >
                Questa pagina permette di aggiornare i prezzi dei prodotti secondo le percentuali definite.
                I prezzi aggiornati negli ultimi 30 giorni verranno saltati.
              </v-alert>

              <v-card outlined class="mt-4">
                <v-card-text v-html="log"></v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-btn
            color="info"
            class="mb-4"
            @click="previewChanges()"
            :loading="previewing"
          >
            Anteprima Modifiche
          </v-btn>

          <v-btn
            color="primary"
            @click="updatePrices()"
            :loading="updating"
          >
            Aggiorna Prezzi
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable quote-props */
/* eslint-disable no-restricted-syntax */

export default {
  name: 'AggiornaListini',

  data: () => ({
    log: '',
    updating: false,
    previewing: false,

    increase: {
      // KEIM Design Lasur Concentrati (esterno) +2%
      'a2btQTB8yB716oOuzf06': {
        0: 1.0, // PG C
        1: 1.01, // PG CS +1%
      },
      // KEIM Design Lasur Concentrati (interno)
      'oEVFXhiTouGFWhcOW5JR': {
        0: 1.0, // PG C
        1: 1.01, // PG CS +1%
      },

      // KEIM AG-09W +5%
      'tYjDLkO7hB0Ja36vaXxv': {
        '*': 1.05,
      },
      // KEIM Turado +5%
      '84qrMwdoomFBQ4WL1BHU': {
        '*': 1.05,
      },
      // KEIM Turado 0,3 +5%
      'uTqWBkb6nzFEr71RYGc6': {
        '*': 1.05,
      },
      // KEIM Innostar +5%
      'H459EIu8H8h6xy0ZGt1p': {
        '*': 1.05,
      },
      // KEIM Innotop +5%
      'zTIaZlrb2ls4Nq1jEsTS': {
        '*': 1.05,
      },
      // KEIM Innotop-Grob +5%
      'OlzTlvkISuEs9UGSS6zm': {
        '*': 1.05,
      },
      // KEIM Biosil-Plus +5%
      'bm7c9LHI4e9VDPoImDcA': {
        '*': 1.05,
      },
      // Stucasol K Bianco -3%, PG1 +2%, PG2 -2%
      'gshfT7cyt0FOTX2UTMJH': {
        0: 0.97, // Bianco -3%
        1: 1.02, // PG1 +2%
        2: 0.98, // PG2 -2%
      },

      // Tutto il resto non va aggiornato
      '*': { '*': 1.0 },
    },
  }),

  computed: {
    products() {
      return this.$store.getters['products/docs'];
    },

    currentUser() {
      return this.$store.getters.currentUser;
    },

    isSuperUser() {
      return this.currentUser.superUser;
    },
  },

  methods: {
    async updateProductPrices(product, preview = false) {
      const update = {
        id: product.id,
        priceranges: [],
      };

      // Recupera i dettagli dell'incremento da applicare
      const increaseData = product.id in this.increase ? this.increase[product.id] : this.increase['*'];
      this.log += `increaseData = ${JSON.stringify(increaseData)}<br>`;

      let productUpdated = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const prIndex in product.priceranges) {
        // eslint-disable-next-line no-prototype-builtins
        if (prIndex in product.priceranges) {
          this.log += `prIndex = ${JSON.stringify(prIndex)}<br>`;
          this.log += `product.priceranges[${prIndex}].name = ${JSON.stringify(product.priceranges[prIndex].name)}<br>`;

          // Verifica se è avvenuto un aggiornamento di recente (un mese) tramite il campo `updatedAt`
          if ('updatedAt' in product.priceranges[prIndex] && product.priceranges[prIndex].updatedAt) {
            const updatedAt = new Date(product.priceranges[prIndex].updatedAt);
            const now = new Date();
            const diff = now - updatedAt;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));

            this.log += `updatedAt = ${updatedAt.toISOString()}<br>`;
            this.log += `now = ${now.toISOString()}<br>`;
            this.log += `diff = ${diff}<br>`;
            this.log += `days = ${days}<br>`;

            if (days < 30) {
              this.log += '<span class="red--text">Aggiornato di recente, mantengo i dati originali</span><br>';
              // Invece di saltare l'intero prodotto, aggiungo il pricerange originale all'aggiornamento
              const originalPricerange = {
                name: product.priceranges[prIndex].name.trim(),
                colors: product.priceranges[prIndex].colors,
                prices: product.priceranges[prIndex].prices,
                updatedAt: product.priceranges[prIndex].updatedAt,
              };
              update.priceranges.push(originalPricerange);
              // eslint-disable-next-line no-continue
              continue;
            }
          }

          const increase = prIndex in increaseData
            ? increaseData[prIndex]
            : increaseData['*'];

          this.log += `increase = ${JSON.stringify(increase)}<br>`;

          // Se l'incremento è 1, non fa nulla
          if (increase === 1) {
            this.log += '<span class="green--text">Incremento = 1 (nessuna modifica)</span><br>';
            // Mantiene il pricerange originale senza modifiche
            const originalPricerange = {
              name: product.priceranges[prIndex].name.trim(),
              colors: product.priceranges[prIndex].colors,
              prices: product.priceranges[prIndex].prices,
              // Mantiene l'updatedAt originale se esiste
              ...(product.priceranges[prIndex].updatedAt && { updatedAt: product.priceranges[prIndex].updatedAt }),
            };
            update.priceranges.push(originalPricerange);
            // eslint-disable-next-line no-continue
            continue;
          }

          // Se l'incremento è diverso da 1, segnala che è necessario un aggiornamento
          productUpdated = true;

          const pricerange = {
            name: product.priceranges[prIndex].name.trim(),
            colors: product.priceranges[prIndex].colors,
            prices: [],
          };

          // Corregge e prepare i prezzi per ogni lattaggio
          // eslint-disable-next-line no-restricted-syntax
          for (const pIndex in product.priceranges[prIndex].prices) {
            if (pIndex in product.priceranges[prIndex].prices) {
              const { id, packaging, ...prices } = product.priceranges[prIndex].prices[pIndex];
              const price = {
                id,
                packaging,
              };

              // eslint-disable-next-line no-restricted-syntax
              for (const plId in prices) {
                if (plId in prices) {
                  const oldPrice = parseFloat(`${prices[plId]}`
                    .replace(',', '.').replace(/[^0-9.]+/g, ''));
                  const newPrice = (oldPrice * increase).toFixed(2);

                  if (preview) {
                    this.log += `<span class="${oldPrice !== Number(newPrice) ? 'red--text' : ''}">${oldPrice} -> ${newPrice}</span><br>`;
                  } else {
                    this.log += `${oldPrice} -> ${newPrice}<br>`;
                  }

                  price[plId] = Number(newPrice);
                }
              }

              pricerange.prices.push(price);

              // Aggiunge il campo `updatedAt` con il timestamp attuale solo se non è una preview
              if (!preview) {
                pricerange.updatedAt = new Date().toISOString();
              }
            }
          }

          update.priceranges.push(pricerange);
        }
      }

      // Se non sono necessari aggiornamenti (tutti gli incrementi sono 1), mostra il messaggio
      if (!productUpdated) {
        this.log += '<span class="green--text">Nessuna modifica necessaria, prodotto saltato</span><br>';
      } else {
        this.log += '<span class="red--text">Modifiche necessarie, prodotto aggiornato</span><br>';
        this.log += `update = ${JSON.stringify(update)}<br>`;
      }

      // Se è una preview, non esegue l'aggiornamento
      if (preview) return Promise.resolve();

      // Se non sono necessari aggiornamenti (tutti gli incrementi sono 1), salta l'aggiornamento
      if (!productUpdated) {
        return Promise.resolve();
      }

      return this.$store.dispatch('products/update', update);
    },

    async previewChanges() {
      this.previewing = true;
      this.log = '<strong>ANTEPRIMA DELLE MODIFICHE</strong><br><br>';

      try {
        // Scorre tutti i prodotti e mostra le modifiche
        for (const index in this.products) { // eslint-disable-line no-restricted-syntax
          if (index in this.products) {
            const product = this.products[index];
            this.log += `<br><strong>Prodotto ${product.id}: ${product.name.it}</strong> (${product.priceType})<br>`;

            // Mostra le modifiche senza applicarle
            await this.updateProductPrices(product, true);
            await new Promise((r) => setTimeout(r, 1000));
          }
        }

        this.log += '<br><strong>Anteprima completata!</strong><br>';
      } catch (error) {
        this.log += `<br><strong class="red--text">Errore durante l'anteprima: ${error.message}</strong><br>`;
      } finally {
        this.previewing = false;
      }
    },

    async updatePrices() {
      this.updating = true;
      this.log = '';

      try {
        // Scorre tutti i prodotti e corregge i prezzi
        for (const index in this.products) { // eslint-disable-line no-restricted-syntax
          if (index in this.products) {
            const product = this.products[index];
            this.log += `<br><strong>Prodotto ${product.id}: ${product.name.it}</strong> (${product.priceType})<br>`;

            // Aggiorna i prezzi del prodotto
            await this.updateProductPrices(product);
            await new Promise((r) => setTimeout(r, 1000));
          }
        }

        this.log += '<br><strong>Aggiornamento completato!</strong><br>';
      } catch (error) {
        this.log += `<br><strong class="red--text">Errore durante l'aggiornamento: ${error.message}</strong><br>`;
      } finally {
        this.updating = false;
      }
    },
  },
};
</script>

<style scoped>
.view-aggiorna-listini {
  padding: 20px;
}
</style>
