<template>
  <div class="view-ripara">
    <v-alert
      v-if="!isSuperUser"
      type="warning"
      text
    >
      Non hai i permessi necessari per accedere a questa pagina.
    </v-alert>

    <template v-else>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8">
          <v-card>
            <v-card-title>
              Riparazione Dati
            </v-card-title>

            <v-card-text>
              <v-alert
                type="info"
                text
              >
                Questa pagina permette di eseguire varie operazioni di riparazione sui dati.
                Usare con cautela.
              </v-alert>

              <v-card outlined class="mt-4">
                <v-card-text v-html="log"></v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-btn
            color="primary"
            @click="fix()"
            :loading="fixing"
          >
            Esegui Riparazione
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable quote-props */
/* eslint-disable no-constant-condition */
/* eslint-disable no-restricted-syntax */

// import { DELETE_USER } from '@/store/actions/users';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'Ripara',

  data: () => ({
    log: '',
    fixing: false,

    increase: {
      'tYjDLkO7hB0Ja36vaXxv': { // KEIM AG-09W
        '*': 1.02, // +2%
      },
      '84qrMwdoomFBQ4WL1BHU': { // KEIM Turado
        '*': 1.05, // +5%
      },
      'uTqWBkb6nzFEr71RYGc6': { // KEIM Turado 0,3
        '*': 1.05, // +5%
      },
      'H459EIu8H8h6xy0ZGt1p': { // KEIM Innostar
        '*': 1.04, // +4%
      },
      'zTIaZlrb2ls4Nq1jEsTS': { // KEIM Innotop
        '*': 1.04, // +4%
      },
      'huLrBHYEuP1vmO7rgmly': { // KEIM Innopro
        '*': 1.04, // +4%
      },
      // TODO: Stucasol K
      'Ay58wq5kpIRfJHYNv4OM': { // KEIM Rasatura
        '*': 1.05, // +5%
      },
      'POAxE4CU8JcFVOR9FR38': { // KEIM Silikatputz
        '*': 1.05, // +5%
      },
      'hDXWQpUzS78FjcBhPkKx': { // KEIM Isosilikat
        '*': 1.05, // +5%
      },
      'SxD7viPmjpBvAXmqOuzQ': { // KEIM Marmosil Liscio
        '*': 1.05, // +5%
      },
      'VG0X3X68aRJPRE3u2ktz': { // KEIM Marmosil Puntinato
        '*': 1.05, // +5%
      },
      'ChivLKTmqjg4TuDUddjf': { // KEIM Mineralspachtel
        '*': 1.05, // +5%
      },

      // Tutto il resto non va aggiornato
      '*': { '*': 1.0 },

      // Senza aumento
      // 'vBRlWeB1g7OkX2ZufXKe': { '*': 1.0 }, // KEIM Mycal Ras Plus
      // 'Ay58wq5kpIRfJHYNv4OM': { '*': 1.0 }, // KEIM Rasatura
      // '': { '*': 1.0 }, // Uniputz
      // 'ChivLKTmqjg4TuDUddjf': { '*': 1.0 }, // KEIM Mineralspachtel

      // + 2%
      // '': { '*': 1.02 }, // Universalputz 0,6
      // '0zhbV9ktIt26T1aW8jQr': { '*': 1.02 }, // Universalputz 1,3

      // + 3%
      // 'W4SErRw8NEDI3acRXKde': { '*': 1.03 }, // Silangrund
      // '': { '*': 1.03 }, // Silan 100
      // '': { '*': 1.03 }, // Brillantputz
      // '': { '*': 1.03 }, // Aqua Royal Armierungsmörtel
      // '': { '*': 1.03 }, // Aqua Royal MP
      // 'shX131rZqyYrD4Mslakm': { '*': 1.03 }, // Pulverkleber 90

      // + 7%
      // '26eVp2mQQvbG2jsEutcr': { // Soldalit
      //   3: 1.07, // Gruppo prezzo 3
      //   '*': 1.06,
      // },

      // + 10%
      // 'QG1gappq1IVElFa9RKxS': { '*': 1.10 }, // Mycal Pannello Lava 25mm
      // 'q0YT3xKx9RHUKLSKEhPp': { '*': 1.10 }, // Mycal Pannello Lava 30mm
      // '84qrMwdoomFBQ4WL1BHU': { '*': 1.10 }, // KEIM Turado
      // 'uTqWBkb6nzFEr71RYGc6': { '*': 1.10 }, // KEIM Turado 0,3
      // '': { '*': 1.10 }, // indulastic-P

      // Tutto il resto + 6%
      // '*': { '*': 1.06 },


      // 'Ay58wq5kpIRfJHYNv4OM': { '*': 1.12 }, // KEIM Rasatura
      // '84qrMwdoomFBQ4WL1BHU': { '*': 1.12 }, // KEIM Turado
      // 'uTqWBkb6nzFEr71RYGc6': { '*': 1.12 }, // KEIM Turado 0,3
      // '26eVp2mQQvbG2jsEutcr': { // Soldalit
      //   3: 1.10, // Gruppo prezzo 3
      //   '*': 1.06,
      // },
      // 'TZWg01apgMgDSIOsA4kQ': { // KEIM Soldalit-Coolit
      //   2: 1.08, // Gruppo prezzo 3
      //   '*': 1.06,
      // },
      // 'POAxE4CU8JcFVOR9FR38': { '*': 1.08 }, // KEIM Silikatputz
      // 'hDXWQpUzS78FjcBhPkKx': { '*': 1.08 }, // KEIM Isosilikat
      // 'SxD7viPmjpBvAXmqOuzQ': { '*': 1.08 }, // KEIM Marmosil Liscio
      // 'VG0X3X68aRJPRE3u2ktz': { '*': 1.08 }, // KEIM Marmosil Puntinato
      // 'ChivLKTmqjg4TuDUddjf': { '*': 1.08 }, // KEIM Mineralspachtel
      // 'vBRlWeB1g7OkX2ZufXKe': { '*': 1.08 }, // KEIM Mycal Ras Plus
      // 'KCyNjfgEh8A13Ss7ElTv': { '*': 1.08 }, // KEIM Silex-OH
      // '*': { '*': 1.06 },
    },

    // exclude: [
    //   'Tju0661CwWMalq4gGmny',
    //   'joguw4XW8yFOOcDG1Kqi',
    //   'naDVI5655xFNxOB1gxg6',
    //   '0REpevpp6TQMc2fqylwa',
    //   'Dxejz3oEQVfbI4FaqLhJ',
    //   'P46HZMpNy2SqQ2bzpH7O',
    //   'jIt2xP1P0MmA4fQJt5Wj',
    //   '8n4Ytr7J0p3zWYxjQV2Q',
    //   '3oVYj5nE2MkuT8iSZ0Iq',
    //   'bav9Dc2foj7WnJVr9ah4',
    //   'QG1gappq1IVElFa9RKxS',
    //   'q0YT3xKx9RHUKLSKEhPp',
    //   'CjACMwfUCOB8rYsjp4FK',
    //   '4UR10ans1aS9Scg4M3qt',
    //   'fM8KdfVTtGq1uChxqtdt',
    //   'htVSexxxCmKsT1LswPHb',
    //   'iYlqlxgU37d7n0ElphlR',
    //   '6PAoi4JbZM5oOUJTOBgo',
    //   'EyLcOf1uBno1VPApQIvm',
    //   '8iJwp6HpWHpK7O1USowr',
    //   'emjayUzuQikb1Ttbsaa5',
    //   'WAsufGDIjOgfQsV7fcFs',
    //   'wqtj8EybcE28tjSJQTBl',
    //   'W4SErRw8NEDI3acRXKde',
    //   'KCyNjfgEh8A13Ss7ElTv',
    //   'TZWg01apgMgDSIOsA4kQ',
    //   'fLjPpHZN1PEc1oiURnSI',
    //   'gshfT7cyt0FOTX2UTMJH',
    //   'Zf76QXlbS7iG6M3ul9sx',
    //   'oJ2jb6Vr3RuDA5hAXCJd',
    //   '7g1rdfFKdBbn8CRnStc1',
    //   '0zhbV9ktIt26T1aW8jQr',
    //   '5zW78QmVTMs7O8Eoxxy1',
    // ],
  }),

  computed: {
    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    products() {
      return this.$store.getters['products/docs'];
    },

    productsWithVariableConsumptionType() {
      return this.products.filter((p) => p.consumptionType === 'variable');
    },

    users() {
      return this.$store.getters['users/docs'];
    },

    quotations() {
      return this.$store.getters['quotations/docs'];
    },

    currentUser() {
      return this.$store.getters.currentUser;
    },

    isSuperUser() {
      return this.currentUser.superUser;
    },
  },

  methods: {
    hasRequiredProducts(product) {
      let result = false;
      product.consumptionranges.forEach((cr) => {
        if (Array.isArray(cr.requiredProducts) && cr.requiredProducts.length > 0) {
          result = true;
        }
      });
      return result;
    },

    showProductPrices(product) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const prIndex in product.priceranges) {
        if (prIndex in product.priceranges) {
          this.log += `${product.priceranges[prIndex].name}<br>`;

          // Corregge e prepare i prezzi per ogni lattaggio
          // eslint-disable-next-line no-restricted-syntax
          for (const pIndex in product.priceranges[prIndex].prices) {
            if (pIndex in product.priceranges[prIndex].prices) {
              const { id, packaging, ...prices } = product.priceranges[prIndex].prices[pIndex];

              this.log += `- packaging: ${packaging}<br>`;

              // eslint-disable-next-line no-restricted-syntax
              for (const plId in prices) {
                if (plId in prices) {
                  this.log += `-- price(${plId}): ${prices[plId]}<br>`;
                }
              }
            }
          }
        }
      }
    },

    async updateProductPrices(product, fake = true) {
      const update = {
        id: product.id,

        priceranges: [],
      };

      // Recupera i dettagli dell'incremento da applicare
      const increaseData = product.id in this.increase ? this.increase[product.id] : this.increase['*'];
      this.log += `increaseData = ${JSON.stringify(increaseData)}<br>`;

      // if (product.consumptionType === 'variable') {
      //   // TODO: Corregge i prezzi dei prodotti richiesti
      //   if (this.hasRequiredProducts(product)) {
      //     product.consumptionranges.forEach((cr, crIndex) => {
      //       this.log += `- CR ${crIndex}<br>`;
      //       if (Array.isArray(cr.requiredProducts) && cr.requiredProducts.length > 0) {
      //         this.log += `-- Prodotti richiesti: ${cr.requiredProducts.length}<br>`;

      //         cr.requiredProducts.forEach((rp, rpIndex) => {
      //           this.log += `--- Prodotto ${rpIndex} con id = ${rp.product.id}<br>`;

      //           // eslint-disable-next-line no-continue
      //           if (this.exclude.includes(rp.product.id)) {
      //             this.log += '<span class="red--text">ESCLUSO</span><br>';
      //           }

      //           this.showProductPrices(rp.product);
      //         });
      //       }
      //     });
      //   }
      // }

      // eslint-disable-next-line no-restricted-syntax
      for (const prIndex in product.priceranges) {
        console.log('prIndex =', prIndex); // eslint-disable-line no-console

        // eslint-disable-next-line no-prototype-builtins
        if (prIndex in product.priceranges) {
          console.log('product.priceranges[prIndex] =', product.priceranges[prIndex]); // eslint-disable-line no-console

          this.log += `prIndex = ${JSON.stringify(prIndex)}<br>`;
          // eslint-disable-next-line max-len
          // this.log += `product.priceranges[${prIndex}] = ${JSON.stringify(product.priceranges[prIndex])}<br>`;

          this.log += `product.priceranges[${prIndex}].name = ${JSON.stringify(product.priceranges[prIndex].name)}<br>`;

          console.log('test 1');

          // Verifica se è avvenuto un aggiornamento di recente (un mese) tramite il campo `updatedAt`
          if ('updatedAt' in product.priceranges[prIndex] && product.priceranges[prIndex].updatedAt) {
            const updatedAt = new Date(product.priceranges[prIndex].updatedAt);
            const now = new Date();
            const diff = now - updatedAt;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));

            this.log += `updatedAt = ${updatedAt.toISOString()}<br>`;
            this.log += `now = ${now.toISOString()}<br>`;
            this.log += `diff = ${diff}<br>`;
            this.log += `days = ${days}<br>`;

            if (days < 30) {
              this.log += '<span class="red--text">Aggiornato di recente</span><br>';
              return Promise.resolve();
            }
          }

          console.log('increaseData =', increaseData); // eslint-disable-line no-console

          const increase = prIndex in increaseData
            ? increaseData[prIndex]
            : increaseData['*'];

          console.log('increase =', increase); // eslint-disable-line no-console
          this.log += `increase = ${JSON.stringify(increase)}<br>`;

          // Se l'incremento è 1, non fa nulla
          if (increase === 1) {
            this.log += '<span class="red--text">Incremento = 1</span><br>';
            return Promise.resolve();
          }

          const pricerange = {
            name: product.priceranges[prIndex].name.trim(),
            // TODO: Verrà rimosso con prezzi per lattagio completi
            // packaging: this.product.priceranges[prIndex].packaging
            //   ? this.product.priceranges[prIndex].packaging.trim()
            //   : null,
            colors: product.priceranges[prIndex].colors,
            prices: [],
          };

          // Corregge e prepare i prezzi per ogni lattaggio
          // eslint-disable-next-line no-restricted-syntax
          for (const pIndex in product.priceranges[prIndex].prices) {
            if (pIndex in product.priceranges[prIndex].prices) {
              const { id, packaging, ...prices } = product.priceranges[prIndex].prices[pIndex];
              const price = {
                id,
                packaging,
              };

              // eslint-disable-next-line no-restricted-syntax
              for (const plId in prices) {
                if (plId in prices) {
                  const oldPrice = parseFloat(`${prices[plId]}`
                    .replace(',', '.').replace(/[^0-9.]+/g, ''));
                  const newPrice = (oldPrice * increase).toFixed(2);

                  this.log += `${oldPrice} -> ${newPrice}<br>`;

                  price[plId] = Number(newPrice);
                }
              }

              pricerange.prices.push(price);

              // Aggiunge il campo `updatedAt` con il timestamp attuale
              pricerange.updatedAt = new Date().toISOString();
            }
          }

          update.priceranges.push(pricerange);
        }
      }

      this.log += `update = ${JSON.stringify(update)}<br>`;

      // Se è un test, non esegue l'aggiornamento
      if (fake) return Promise.resolve();

      return this.$store.dispatch('products/update', update);
    },

    async fix() {
      this.fixing = true;
      this.log = '';

      try {
        if (false) {
          this.log += '<br><strong>Aggiorno gli utenti memorizzati nei preventivi...</strong><br><br>';

          await this.$store.dispatch('users/fixQuotationsUsers');
        }

        if (false) {
          this.log += '<br><strong>Correggo gli id degli utenti...</strong><br><br>';

          await this.$store.dispatch('users/fixUserIds');
        }

        if (false) {
          this.log += '<br><strong>Correggo gli utenti associati ai preventivi esistenti...</strong><br><br>';

          this.quotations.forEach((q) => {
            // Verificao se l'utente è tra quelli legacy

            const user = this.users.find((u) => u.id !== u.uid && u.uid === q.uid);

            if (user) {
              this.log += `<br><strong>Il preventivo ${q.id} sembra associato ad un utente legacy</strong><br>`;
              this.log += `<br>Utente con id = ${user.id} e uid = ${user.uid}<br>`;
              this.log += `<br>quotation.uid = ${q.uid} (da correggere in ${user.uid})<br>`;
            }
          });
        }

        if (false) {
          this.log += '<br><strong>Elimino gli utenti duplicati (legacy)...</strong><br><br>';

          const promises = [];

          this.users.forEach((u) => {
            if (u.id !== u.uid) {
              this.log += `<br>Elimino l'utente ${u.email} (id = ${u.id})<br>`;

              // TODO: Eliminare l'utente corretto, quello con il Legacy Id.
              // promises.push(this.$store.dispatch(`users/${DELETE_USER}`, { user: u }));
            }
          });

          await Promise.all(promises);
          this.log += '<br><strong>Eliminazione completata!.</strong><br>';
        }

        if (false) {
          this.log += '<br><strong>Creo la collection con l\'elenco dei preventivi "light"...</strong><br><br>';

          await this.$store.dispatch('quotations/createQuotationsLight');
        }

        if (false) {
          this.log += '<br><strong>Creo la collection con l\'elenco dei prodotti "light"...</strong><br><br>';

          await this.$store.dispatch('products/createProductsLight');
        }

        if (false) {
          // eslint-disable-next-line no-unreachable
          this.log += '<br><strong>Aggiorno i "Prodotti richiesti" alla versione attuale...</strong><br><br>';

          const fixedProducts = [];

          this.productsWithVariableConsumptionType.forEach((product) => {
            if (this.hasRequiredProducts(product)) {
              this.log += `Prodotto: ${product.name.it}<br>`;

              const newProduct = JSON.parse(JSON.stringify(product));
              // this.log += `${JSON.stringify(fixed)}<br>`;

              product.consumptionranges.forEach((cr, crIndex) => {
                this.log += `- CR ${crIndex}<br>`;
                if (Array.isArray(cr.requiredProducts) && cr.requiredProducts.length > 0) {
                  this.log += `-- Prodotti richiesti: ${cr.requiredProducts.length}<br>`;

                  cr.requiredProducts.forEach((rp, rpIndex) => {
                    this.log += `--- Prodotto ${rpIndex} con id = ${rp.product.id}<br>`;

                    if (true) {
                      const fixed = this.products.find((p) => p.id === rp.product.id);
                      if (fixed) {
                        newProduct.consumptionranges[crIndex].requiredProducts[rpIndex]
                          .product = { ...fixed };
                        this.log += `--- Prodotto ${rpIndex} corretto!<br>`;
                      } else {
                        this.log += `--- Prodotto ${rpIndex} non corretto!<br>`;
                      }
                    }
                  });
                }
              });

              if (fixedProducts.findIndex((fp) => fp.id === newProduct.id) < 0) {
                fixedProducts.push(newProduct);
              }
            }
          });

          // this.log += `${JSON.stringify(fixedProducts)}`;

          // eslint-disable-next-line no-restricted-syntax, no-unreachable
          for (const fp of fixedProducts) {
            this.log += `Correggo ${fp.id}<br>`;

            // eslint-disable-next-line no-await-in-loop
            await this.$store.dispatch('products/update', fp);
          }
        }

        if (false) {
          // Correggo i preventivi generando un sId per i prodotti selezionati che non ce l'hanno

          let promises = [];

          this.quotations.forEach((q) => {
            // Corregge solo il preventivo di test (Villa Ginosa)
            // if (q.id !== 'z4ci08zMAcOVYKCUqej4') return;

            this.log += `Verifico il preventivo ${q.id} (${q.name})<br>`;

            // Ottengo l'elenco dei prodotti selezionati
            const { selectedProducts } = q;

            // Corregge ogni prodotto selezionato che non ha un sId
            // eslint-disable-next-line no-restricted-syntax
            for (const sp of selectedProducts) {
              if ('sId' in sp === false || sp.sId === null) {
                this.log += `- Correggo il prodotto ${sp.id}<br>`;
                sp.sId = uuidv4();
              }
            }

            promises.push(this.$store.dispatch('quotations/update', {
              id: q.id,
              selectedProducts,
            }));
          });

          // HACK: Riduce l'array di promises a 2 elementi per evitare il timeout
          promises = promises.slice(0, 2);

          // Eseguo le correzioni in serie
          // eslint-disable-next-line no-restricted-syntax
          for (const p of promises) {
            // eslint-disable-next-line no-await-in-loop
            await p;

            // Attende 2 secondi prima di procedere
            // eslint-disable-next-line no-await-in-loop
            await new Promise((r) => setTimeout(r, 10000));
          }
        }

        if (false) {
          this.log += '<br><strong>Trasferisco gli allegati singoli nel nuovo array attachments...</strong><br><br>';

          for (const index in this.products) {
            if (index in this.products) {
              const product = this.products[index];

              // Verifica se il prodotto ha un url ma non ha attachments
              if (product.url && (!Array.isArray(product.attachments) || !product.attachments.length)) {
                this.log += `<br>Prodotto ${product.id}: ${product.name.it}<br>`;
                this.log += `- URL trovato: ${product.url}<br>`;
                this.log += '- L\'array attachments è vuoto<br>';

                const update = {
                  id: product.id,
                  attachments: [{
                    type: 'link',
                    category: 'scheda-tecnica',
                    customCategory: null,
                    name: '',
                    url: product.url,
                  }],
                };

                // Lascia il vecchio url come fallback per le App non ancora aggiornate
                // update.url = null;

                // Aggiorna il prodotto
                await this.$store.dispatch('products/update', update);
                this.log += '- Allegato trasferito correttamente<br>';

                // Attende 1 secondo prima di procedere con il prossimo prodotto
                await new Promise((r) => setTimeout(r, 1000));
              }
            }
          }

          this.log += '<br><strong>Trasferimento allegati completato!</strong><br>';
        }

        this.log += 'Fine!\n';
      } catch (error) {
        this.log += `<br><strong class="red--text">Errore durante la riparazione: ${error.message}</strong><br>`;
      } finally {
        this.fixing = false;
      }
    },
  },
};
</script>

<style>
.view-ripara {
  flex-grow: 1;
}
</style>
