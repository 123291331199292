<template>
  <div class="view-test">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Configurazione Test
            </v-card-title>

            <v-card-text>
              <v-alert
                v-if="!user.superUser"
                type="warning"
                text
              >
                Non hai i permessi necessari per accedere a questa pagina.
              </v-alert>
              <v-alert
                v-else-if="published"
                type="success"
                text
              >
                Configurazione pubblicata con successo!
              </v-alert>

              <div v-if="user.superUser">
                <v-textarea
                  v-model="configJson"
                  label="Configurazione JSON"
                  rows="20"
                  :error-messages="jsonError"
                ></v-textarea>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-speed-dial
      v-if="user.superUser"
      v-model="fab"
      fixed
      bottom
      right
      direction="top"
      open-on-hover
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          dark fab large
          color="success">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-btn fab dark small color="orange"
        @click.stop="publishDialog = true">
        <v-icon>mdi-publish</v-icon>
      </v-btn>
    </v-speed-dial>

    <PublishTestDialog
      v-model="publishDialog"
      :config="parsedConfig"
      @published="onPublished" />
  </div>
</template>

<script>
import PublishTestDialog from '@/components/PublishTestDialog';

export default {
  name: 'Test',

  components: {
    PublishTestDialog,
  },

  data: () => ({
    configJson: '',
    jsonError: '',
    published: false,
    fab: false,
    publishDialog: false,
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },

    parsedConfig() {
      try {
        return JSON.parse(this.configJson);
      } catch (error) {
        return null;
      }
    },
  },

  async created() {
    if (this.user.superUser) {
      const testComponent = await import('@/components/Test.vue');
      this.configJson = JSON.stringify(testComponent.default.data().test, null, 2);
    }
  },

  methods: {
    onPublished() {
      this.published = true;
      setTimeout(() => {
        this.published = false;
      }, 3000);
    },
  },
};
</script>
